import { Injectable } from '@angular/core';
import { IBrowserNotificationSettings } from '@shared/core/interfaces/browser-notification-settings.interface';

@Injectable({
  providedIn: 'root',
})
export class BrowserNotificationsService {
  public get currentTabHash(): string {
    return sessionStorage.getItem(this._tabHashKey);
  }

  private _defaultNotificationOptions = { icon: '/assets/logos/icon-512x512.png' };
  private _notificationsQueue: IBrowserNotificationSettings[] = [];
  private _notifications: Set<Notification> = new Set<Notification>();
  private _tabHashKey = 'currentTabHash';
  private _lastFocusedTabKey = 'lastFocusedTab';

  public async init(): Promise<void> {
    try {
      this._initTabHash();
      if (!Notification || Notification.permission === 'denied') {
        return;
      }
      if (Notification.permission === 'granted' || (await Notification.requestPermission()) === 'granted')
        this._showNotificationsQueue();
    } catch (error) {
      console.error(error);
    }
  }

  public onFocus(): void {
    localStorage.setItem(this._lastFocusedTabKey, this.currentTabHash);
  }

  public destroy(): void {
    if (this._isCurrentTabFocused()) localStorage.removeItem(this._lastFocusedTabKey);
  }

  public pushBrowserNotification(notification: IBrowserNotificationSettings): void {
    if (!('Notification' in window)) {
      console.log('Notifications not supported');
      return;
    }
    switch (Notification?.permission) {
      case 'granted':
        return this._createNotification({ ...notification });
      case 'default':
        this._notificationsQueue.push({ ...notification });
    }
  }

  private _createNotification(settings: IBrowserNotificationSettings): void {
    if (!settings?.forced && !this._isCurrentTabFocused()) return;

    const options = { ...this._defaultNotificationOptions, ...settings.options };
    const notification = new Notification(settings.title, options);
    this._notifications.add(notification);
    notification.onclose = () => this._notifications.delete(notification);
    notification.onclick = settings.onclick;
  }

  private _showNotificationsQueue(): void {
    this._notificationsQueue.forEach((notification) => this._createNotification(notification));
    this._notificationsQueue = [];
  }

  private _isCurrentTabFocused(): boolean {
    return localStorage.getItem(this._lastFocusedTabKey) === this.currentTabHash;
  }

  private _initTabHash(): void {
    const hash = `${Date.now()}`;
    sessionStorage.setItem(this._tabHashKey, hash);
    if (document.hasFocus() || !localStorage.getItem(this._lastFocusedTabKey))
      localStorage.setItem(this._lastFocusedTabKey, hash);

    window.addEventListener('focus', () => this.onFocus());
    window.addEventListener('beforeunload', () => this.destroy());
    window.addEventListener('storage', (event: StorageEvent) => {
      if (event.key === this._lastFocusedTabKey && !event.newValue) this.onFocus();
    });
  }
}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@pw-utils/services/auth/auth.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as Sentry from '@sentry/angular';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        // auto logout and handle error if 401 response returned from API
        if (err.status === 401) {
          console.warn('received a 401');
          if (err?.details) {
            console.warn(err.details);
          }
          if (err?.code) {
            console.warn(err.code);
          }
          console.error(err)
          this.authService.logout(true);
          return throwError(err?.error);
          // return of(null).pipe(take(0));
        }

        if (err.status === 403) {
          console.warn('received a 403');
          if (err?.details) {
            console.warn(err.details);
          }
          if (err?.code) {
            console.warn(err.code);
          }
          console.error(err)
          if (!this.authService.hasAdminPreference()) {
            console.error(`received a 403 -> going to log you out...`)
            this.authService.logout(true);
          }
          return throwError(err?.error);
          // return of(null).pipe(take(0));
        }


        if (err.status === 404) {
          console.log(`intercepted 404 error to url: ${request.urlWithParams}`);
          console.error(err);
          Sentry.captureException(err);
          return throwError(err?.error);
        }

        // err.error will give the details payload from the server
        return throwError(err?.error);
      })
    );
  }
}
